import React from "react";
import { SimpleGrid } from "@chakra-ui/react";

import GridCard from "./grid_card"


const ProductGrid = (props) => {
    const products = props.products;
    
    return ( 
    <SimpleGrid 
        mx={{ base: 0, md: "10%"}}
        w={{ base: "100%", md: "80%"}}
        minChildWidth={{ base: "150px", sm: "250px"}}
        spacing={{ base: "10px", sm: "20px"}}
    >
        {products.map(p =>
            <GridCard
                key={p.contentful_id}
                id={p.contentful_id}
                name={p.name}
                prices={p.prices}
                discount={p.discount}
                packUnit={p.packunitSizes}
                imageUrl={p.imageLowRes.file.url}
                catName={p.categoryAndSubCategory.categoryName}
                subCatName={p.categoryAndSubCategory.subCategoryName}
                moq={p.moq}
            />
        )}
    </SimpleGrid>

    );
};
  
export default ProductGrid;
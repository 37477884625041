import React from "react";
import { 
    HStack,
    Input,
    IconButton,
    useNumberInput
} from "@chakra-ui/react"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

// TODO: prevent number input from showing up when clicking button on mobile
const Quantity = (props) => {
    const moq = (!props.moq) ? 1 : props.moq;
    const {
        getInputProps,
        getIncrementButtonProps,
        getDecrementButtonProps,
    } = useNumberInput({
        step: 1,
        defaultValue: moq,
        min: moq,
        max: 100,
        precision: 0,
    })
    
    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps({ isReadOnly: false })
    props.onChange(input.value);

    return (
        <HStack maxW="100%" spacing={0}>
            <IconButton
                size="sm"
                variant="solid"
                colorScheme="blue"
                aria-label={"Decrease quantity for product " + props.name}
                icon={<MdChevronLeft size={25}/>}
                {...dec}/>
            <Input {...input} />
            <IconButton
                size="sm"
                variant="solid"
                colorScheme="blue"
                aria-label={"Increase quantity for product " + props.name}
                icon={<MdChevronRight size={25}/>}
                {...inc}/>
        </HStack>
    )
};

export default Quantity;